// Context for URL params (useParam) data.
//
// Instead of using useParam to retrieve the parameters we use a Context, so those
// parameters can be set by a component that uses this. That component can obtain
// ir from the URL (useParam), or from other source (i.e. to embed this for CSV
// upload).
import React from 'react';

const initialState = {
  dataType: null,
  id: null
};

const ParamsContext = React.createContext(initialState);

export default ParamsContext;
