import React, { Fragment, memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import * as colors from '@constants/colors';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Collapse from '@material-ui/core/Collapse';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import './batch-upload-entities-select-fields.scss';

const NESTED_CELL_STYLE = {
  border: 'none',
  paddingBottom: 0,
  paddingTop: 0
};

const NestedCell = ({ open, children, colSpan = 1 }) => (
  <TableCell colSpan={colSpan} style={NESTED_CELL_STYLE}>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <div style={{ paddingBottom: '0.75rem', paddingTop: '0.75rem' }}>
        {children}
      </div>
    </Collapse>
  </TableCell>
);

NestedCell.propTypes = {
  children: PropTypes.node,
  colSpan: PropTypes.number,
  open: PropTypes.bool
};

const Row = ({ children, disabled, fields, label, name, onToggle, required }) => {
  const [open, setOpen] = useState(true);

  const onCollapseToggle = useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} onClick={onCollapseToggle} style={{ cursor: children ? 'pointer' : 'default' }}>
        <TableCell>
          <Switch
            checked={fields[name] || false}
            color="primary"
            disabled={disabled}
            name={name}
            onChange={onToggle}
          />
        </TableCell>
        <TableCell>
          {label}
        </TableCell>
        <TableCell>
          <ArrowRightAltIcon htmlColor={colors.dotmapsDisabledText} />
        </TableCell>
        <TableCell>
          {label}{required ? ' *' : ''}
        </TableCell>
      </TableRow>
      {children &&
        <Fragment>
          <TableRow>
            <TableCell style={NESTED_CELL_STYLE}>{ /* empty switch */ }</TableCell>
            <NestedCell colSpan={3} open={open} children={children.header} />
          </TableRow>
          {children.rows.map(row => (
            <TableRow key={row}>
              <TableCell style={NESTED_CELL_STYLE}>{ /* empty switch */ }</TableCell>
              <NestedCell open={open} children={row} />
              <NestedCell open={open} children={<ArrowRightAltIcon htmlColor={colors.dotmapsDisabledText} />} />
              <NestedCell open={open} children={row} />
            </TableRow>
          ))}
        </Fragment>
      }
    </Fragment>
  );
};

Row.propTypes = {
  children: PropTypes.object,
  disabled: PropTypes.bool,
  fields: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onToggle: PropTypes.func,
  required: PropTypes.bool
};

export default memo(Row);
