import axios from 'axios';
import {
  AUTH_TOKEN_NAME,
  AUTH_LAST_ACTIVITY_TIME,
  AUTH_USERNAME,
  COOKIE_PATH,
  DISCLAIMER_KEY
} from '@constants/auth';
import { getConfig } from '@utils/config-utils';
import { clearPermissions } from '@utils/permission-utils';
import { getCookies } from '@utils/shared-utils';

// Returns the auth token.
const getAuthToken = () => getCookies().get(AUTH_TOKEN_NAME);

// Deletes the auth token.
const clearAuthToken = () => {
  getCookies().remove(AUTH_TOKEN_NAME, COOKIE_PATH);
  getCookies().remove(AUTH_LAST_ACTIVITY_TIME, COOKIE_PATH);
};

// Return the last time the user had some interaction with the application:
const getLastActivity = () => getCookies().get(AUTH_LAST_ACTIVITY_TIME);

// Checks if we must logout the user due to inactivity.
//
// Inactivity is already handled by the IdleMonitor class,
// but since it's javascript code, it needs to be running in order to log us out.
//
// If we close the browser window without shutting down the browser, the
// cookie won't expire, thus opening a new window or tab with this application,
// even if a day passed, will authenticate us again automatically.
//
// Since it's a SPA application, session is not handled by the server,
// (servers update the session id cookie's expiration time on each request),
// thus we need to check manually here if the user was idle for more than
// the configured timeout time.
//
// Thus we have two checks, one in Javascript which watches for page events,
// and this one, which checks the cookie time.
const isSessionExpired = () => {
  const elapsed = (+new Date()) - getLastActivity();
  const config = getConfig();
  if (config && elapsed < config.idleTimeout) {
    return false;
  }

  return true;
};

export const setAuthLastActivityTime = () => getCookies().set(AUTH_LAST_ACTIVITY_TIME, +new Date(), COOKIE_PATH);

// Saves the auth token.
export const setAuthToken = token => {
  getCookies().set(AUTH_TOKEN_NAME, token, COOKIE_PATH);
  // Save last activity time when saving the token the first time.
  setAuthLastActivityTime();
};

// Clear the auth header for Axios.
const clearAuthHeaders = () => {
  axios.defaults.headers.common.Authorization = null;
};

// Set the 'Token:' auth header for Axios calls.
export const setAuthHeaders = () => {
  const token = getAuthToken();
  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    clearAuthHeaders();
  }
};

// Returns the authenticated username:
export const getAuthUsername = () => getCookies().get(AUTH_USERNAME);

// Clears the username.
const clearAuthUsername = () => getCookies().remove(AUTH_USERNAME, COOKIE_PATH);

// Saves the username.
export const setAuthUsername = username => getCookies().set(AUTH_USERNAME, username, COOKIE_PATH);

// Returns the default redirect route (either the one specified in the URL
// with the 'redirect' parameter or the one defined as default).
export const getDefaultRoute = (location, defaultUrl) => {
  if (location && location.search) {
    const search = decodeURIComponent(location.search);
    const redirectKey = '?redirect=';
    // Find the path where to redirect, specified by the 'redirect' URL parameter.
    if (search.startsWith(redirectKey)) {
      const redirect = search.substring(redirectKey.length);
      // Don't return the redirect parsed path if it's empty, or it's the root page.
      if (redirect && redirect !== '/') {
        return redirect;
      }
    }
  }
  // If no 'redirect' parameter is set, use the default URL from the configuration.
  return defaultUrl;
};

export const validatePasswords = (firstPassword, secondPassword) => {
  /* basic password validation - checks for inequality or empty strings
     returns relevant error text as a string */
  if (firstPassword === '' || secondPassword === '') {
    return 'Both password fields are required.';
  } else if (firstPassword !== secondPassword) {
    return 'Passwords do not match.';
  }
  return '';
};

export const validateEmail = email => {
  /* basic regex for email validation from https://stackoverflow.com/a/9204568
     returns true if valid */
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

// Clears all the authentication information:
export const clearAuth = () => {
  clearAuthToken();
  clearAuthUsername();
  clearAuthHeaders();

  // Also clear permissions, since that's part
  // of the authentication data.
  clearPermissions();

  // Clear disclaimer flag:
  getCookies().remove(DISCLAIMER_KEY, COOKIE_PATH);
};

// Auth selector function for redux-auth-wrapper (Route component wrapping).
export const authSelector = () => {
  // If the session expired, clear the authentication data.
  if (isSessionExpired()) {
    clearAuth();
  }
  // If the auth token is set, we are authenticated.
  const token = getAuthToken();
  return typeof token !== 'undefined';
};
