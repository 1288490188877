import React, { memo, useCallback, useContext, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { replace } from 'connected-react-router';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';

import { colors } from '@constants/colors';

import BlockIcon from '@material-ui/icons/Block';
import InfoIcon from '@material-ui/icons/Info';

import DataCard from '@shared/ui-library/card/data-card';
import AttributeLabelContainer from '@shared/ui-library/attribute/attribute-label-container';

import './drawer-row-item-card.scss';

const DrawerRowItemCard = ({ index, row }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { batch, fileId, skipped } = useContext(BatchContext);

  const selected = useMemo(() => {
    // Build the path for this card to check if it's selected:
    const path = `/batch-upload-edit/${fileId}/${row.id}/`;
    // Get the current location pathname and add a trailing slash if it's not
    // already there, for comparison:
    const url = location.pathname.replace(/\/?$/, '/');
    return url.startsWith(path);
  }, [location.pathname, fileId, row.id]);

  const showEntity = useCallback(() => {
    const path = `/batch-upload-edit/${fileId}/${row.id}/`;
    dispatch(replace(path));
  }, [dispatch, fileId, row.id]);

  const nameField = useMemo(() => `${batch.entity}_name`, [batch.entity]);

  const title = useMemo(() => (
    <span styleName="row-list-item-title">
      ROW {row.display_row_nums}
    </span>
  ), [row.display_row_nums]);

  const indexBlock = useMemo(() => (
    <span styleName="row-list-item-index">
      {index}
    </span>
  ), [index]);

  const icon = useMemo(() => {
    if (skipped[row.id] === true) {
      return <BlockIcon style={{ color: '#ED6C02' }} />;
    }
    if (row.hasErrors) {
      return <InfoIcon style={{ color: colors.utility.muiError }} />;
    }
    return null;
  }, [row.hasErrors, row.id, skipped]);

  return (
    <DataCard
      borderRadius={false}
      icon={indexBlock}
      iconRight={icon}
      onClick={showEntity}
      selected={selected}
      title={title}
    >
      <span styleName="row-list-item-name">
        {row.data[nameField]}
      </span>
      <AttributeLabelContainer>
        <span styleName="row-list-item-secondary-text">
          {row.data.id && <span>Updated&nbsp;&bull;&nbsp;ID&nbsp;{row.data.id}</span>}
          {!row.data.id && <span>New</span>}
        </span>
      </AttributeLabelContainer>
    </DataCard>
  );
};

DrawerRowItemCard.propTypes = {
  index: PropTypes.number,
  row: PropTypes.object
};

export default memo(DrawerRowItemCard);
