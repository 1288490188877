/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { cloneDeep, isEmpty } from 'lodash';

import LocationContext from '@components/entity/info/location-context';
import ParamsContext from '@components/entity/info/params-context';
import SegmentEditListItem from '@components/forms/segment-edit-list-item';
import SegmentListItem from '@components/forms/segment-list-item';

import { detailEdit } from '@constants/mui-theme';

import DataContext from '@forms/data-context';

import { zipCoordinatePair } from '@utils/map-utils';

import './segment-list.scss';

const SegmentList = ({ readOnly }) => {
  const { dataType } = useContext(ParamsContext);
  const { data, errors } = useContext(DataContext);
  const segments = useMemo(() => data?.segments || [], [data?.segments]);
  const {
    activeSegmentId,
    setActiveType,
    setActiveSegmentId,
    setActiveStart,
    setActiveEnd,
    setGeometryLocked
  } = useContext(LocationContext);

  // When datatype changes, set the active location to none:
  useEffect(() => {
    setActiveSegmentId(null);
  }, [dataType, setActiveSegmentId]);

  // Each time the segment changes, check if we should open first one.
  useEffect(() => {
    // if the first segment has a temporal id (i.e. is a new one) and there's no other
    // segment currently selected, open the new one, since it means it was newly
    // added or the page just first loaded.
    if (
      segments?.length > 0 &&
      String(segments[0].id).startsWith('temp-') &&
      activeSegmentId === null
    ) {
      setActiveSegmentId(segments[0].id);
      setActiveType('LineString');
      setActiveStart(null);
      setActiveEnd(null);
    }
  }, [activeSegmentId, segments, setActiveSegmentId, setActiveType, setActiveStart, setActiveEnd]);

  useEffect(() => {
    setGeometryLocked(readOnly);
    if (readOnly) {
      setActiveSegmentId(null);
      setActiveStart(null);
      setActiveEnd(null);
    }
  }, [readOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSegmentErrors = useCallback(index => {
    if (errors?.segments?.length > index && Object.keys(errors?.segments?.[index]).length > 0) {
      return errors?.segments?.[index];
    }
    return null;
  }, [errors?.segments]);

  const onSegmentSelected = useCallback(segmentId => {
    if (activeSegmentId !== segmentId) {
      setActiveSegmentId(segmentId);
      setGeometryLocked(readOnly);
      const segment = segments.find(seg => seg.id === segmentId);
      switch (segment && segment.shape && segment.shape.type) {
      case 'LineString':
        setActiveType('LineString');
        setActiveStart(zipCoordinatePair(segment.shape.coordinates[0]));
        setActiveEnd(zipCoordinatePair(segment.shape.coordinates[segment.shape.coordinates.length - 1]));
        break;
      case 'Point':
        setActiveType('Point');
        setActiveStart(zipCoordinatePair(segment.shape.coordinates));
        setActiveEnd(null);
        break;
      case 'Polygon':
        setActiveType('Polygon');
        setActiveStart(null);
        setActiveEnd(null);
        break;
      default:
        setActiveType('LineString');
        setActiveStart(null);
        setActiveEnd(null);
        break;
      }
    } else {
      setActiveSegmentId(null);
    }
  }, [activeSegmentId, readOnly, setActiveEnd, setActiveSegmentId, setActiveStart, setActiveType, setGeometryLocked, segments]);

  if (!segments) {
    return null;
  }

  return (
    <div styleName="segment-list" style={detailEdit.columnStyles.col100}>
      {segments.map((segment, index) => {
        const onSegmentSelectedLocal = () => onSegmentSelected(segment.id);
        // Clone the error object, since we'll modify it:
        const segmentErrors = cloneDeep(getSegmentErrors(index));
        let hasEmptyAddressError = false;
        // Catch the global segment error about empty addresses, and erase it,
        // since we must show an error for each one of those fields instead.
        if (segmentErrors &&
            segmentErrors.shape &&
            segmentErrors.shape.length > 0 &&
            segmentErrors.shape[0] === 'A segment address must use a real address, street name or cross street.') {
          hasEmptyAddressError = true;
          delete segmentErrors.shape;
        }
        const hasErrors = !isEmpty(segmentErrors);
        const activeStyleName = activeSegmentId === segment.id ? 'active' : '';
        const errorStyleName = hasErrors ? 'segment-list-item-container-error' : '';
        return (
          <div
            key={segment.id}
            styleName={`segment-list-item-container ${activeStyleName} ${errorStyleName}`}
          >
            {hasErrors &&
              <div styleName="segment-error">{[].concat(R.values(segmentErrors)).join(', ')}</div>
            }
            {activeSegmentId !== segment.id &&
              <SegmentListItem
                checkAddressErrors={hasEmptyAddressError}
                data={data}
                dataType={dataType}
                onClick={onSegmentSelectedLocal}
                segment={segment}
              />
            }
            {activeSegmentId === segment.id &&
              <SegmentEditListItem
                checkAddressErrors={hasEmptyAddressError}
                data={data}
                dataType={dataType}
                onClick={onSegmentSelectedLocal}
                readOnly={readOnly}
              />
            }
          </div>
        );
      })}
    </div>
  );
};

SegmentList.propTypes = {
  readOnly: PropTypes.bool
};

export default memo(SegmentList);
