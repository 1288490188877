// Context for form data.
import React from 'react';

const initialState = {
  data: null,
  errors: null,
  modified: false
};

const DataContext = React.createContext(initialState);

export default DataContext;
