/* eslint-disable react/no-danger */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  errorDataField,
  updateDataField
} from '@actions/data-detail-actions';

import ScheduleWarning from '@components/entity/info/drawer/form/form-tab/schedule-warning';
import ScrollIntoFirstError from '@components/entity/info/drawer/form/scroll-into-first-error';
import ParamsContext from '@components/entity/info/params-context';
import DrawerSectionTitle from '@components/public/drawer-section-title';
import DrawerReviewSectionTitle from '@components/public/drawer-review-section-title';
import Files from '@components/public/files';

import * as colors from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import { detailEdit } from '@constants/mui-theme';

import FormElement from '@forms/form-element';
import DateRange from '@forms/date-range';
import SegmentList from '@forms/segment-list';

import Alert from '@material-ui/lab/Alert';

import { getMetadata, getVisibleFields } from '@selectors/forms-selector';

import { isFieldVisible } from '@utils/form-utils';

import '../forms/forms.scss';

const DrawerFormTab = ({ tab, tabs }) => {
  const dispatch = useDispatch();
  const { dataType, id } = useParams();
  const { data, error: errors } = useSelector(state => state.dataDetail);
  const metadata = useSelector(state => getMetadata(dataType, true)(state));
  const visibleFields = useSelector(state => getVisibleFields(dataType, true)(state));

  const dataTypeDisplayName = useMemo(() => getEntityTypeLabel(dataType), [dataType]);

  const contextValue = useMemo(() => ({ dataType, id }), [dataType, id]);

  const readOnly = useMemo(() => Boolean(data.id), [data.id]);

  const handleError = useCallback((field, error) => {
    dispatch(errorDataField(field, error, false));
  }, [dispatch]);

  const updateField = useCallback(
    (field, value) => {
      dispatch(updateDataField(field, value));
      if (metadata[field].required) {
        const requiredError = 'This field is required.';
        if (value && (!errors[field] || errors[field].includes(requiredError))) {
          handleError(field, null);
        }
      }
    },
    [dispatch, errors, handleError, metadata]
  );

  const onError = useCallback((...args) => dispatch(errorDataField(...args)), [dispatch]);

  return (
    <div key={`tab-section-${tab.id}`} styleName="form-tab">
      <DrawerSectionTitle tab={tab} readOnly={readOnly} />
      <ParamsContext.Provider value={contextValue}>
        <div>
          <ScrollIntoFirstError />
          {tab.fields && tab.fields.map(fieldName => {
            if (!isFieldVisible(fieldName, visibleFields)) {
              return null;
            }
            switch (fieldName) {
            case '_date_warning':
              return <ScheduleWarning />;
            case 'segments':
              return (
                <div key={fieldName}>
                  <SegmentList />
                </div>
              );
            // Render a textarea with a configured text.
            case '_textarea':
              return (
                <div
                  style={detailEdit.columnStyles.col100}
                  styleName="col100 textarea"
                  dangerouslySetInnerHTML={{__html: tab._textarea_data}}
                />
              );
            // Render an info message.
            case '_info_message':
              return (
                <div style={detailEdit.columnStyles.col100} styleName="col100">
                  <Alert severity="info">
                    <div dangerouslySetInnerHTML={{__html: tab._info_message}} />
                  </Alert>
                </div>
              );
            case '_date_range':
              return (
                <DateRange key={fieldName} fieldNames={tab.date_range_fields} isPublic readOnly={readOnly} />
              );
            default:
              return (
                <div key={fieldName}>
                  {metadata[fieldName].title && <DrawerSectionTitle tab={metadata[fieldName].title} />}
                  <FormElement
                    data={data}
                    dataType={dataTypeDisplayName}
                    errors={errors}
                    key={fieldName}
                    fieldMeta={metadata[fieldName]}
                    fieldName={fieldName}
                    onChange={updateField}
                    onError={onError}
                    readOnly={readOnly}
                    value={data[fieldName]}
                  />
                </div>
              );
            } // end switch
          })}
          {tab.id === 'review' &&
            <div>
              <div styleName="review-spacer" />
              {tabs.map(reviewTab => {
                // re-map through the tabs and fields to build a review page of all the fields
                if (reviewTab.id === 'review' || reviewTab.id === 'documents') {
                  return null;
                }
                return (
                  <div key={`review-tab-section-${reviewTab.id}`}>
                    <DrawerReviewSectionTitle tab={reviewTab} />
                    {reviewTab.fields.map(fieldName => {
                      switch (fieldName) {
                      case '_date_warning':
                        return null;
                      case '_textarea':
                        return (
                          <div
                            style={{
                              ...detailEdit.columnStyles.col100,
                              color: colors.dotmapsBlack40
                            }}
                            styleName="col100 textarea"
                            dangerouslySetInnerHTML={{__html: reviewTab._textarea_data}}
                          />
                        );
                      // Don't render the info messages on the review step.
                      case '_info_message':
                        return null;
                      case 'segments':
                        return (
                          <div key={fieldName}>
                            <SegmentList readOnly />
                          </div>
                        );
                      case '_date_range':
                        return (
                          <DateRange key={fieldName} fieldNames={reviewTab.date_range_fields} isPublic readOnly />
                        );
                      default:
                        return (
                          <div key={fieldName}>
                            {metadata[fieldName].title && <DrawerSectionTitle tab={metadata[fieldName].title} />}
                            <FormElement
                              data={data}
                              key={fieldName}
                              fieldMeta={metadata[fieldName]}
                              fieldName={fieldName}
                              readOnly
                              value={data[fieldName] || ''}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          }
          {tab.id === 'documents' && <Files params={tab} />}
        </div>
      </ParamsContext.Provider>
    </div>
  );
};

DrawerFormTab.propTypes = {
  tab: PropTypes.object,
  tabs: PropTypes.array
};

export default memo(DrawerFormTab);
