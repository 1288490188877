/* eslint-disable guard-for-in */
/* eslint-disable max-depth */
import { memo, useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';

import ParamsContext from '@components/entity/info/params-context';

import DataContext from '@forms/data-context';

import { getVisibleFields } from '@selectors/forms-selector';

// Scroll into the specified error field and return it:
const getErrorElement = field => {
  const errorKey = `error-${field}`;
  const errorElement = document.getElementById(errorKey);
  if (errorElement) {
    errorElement.parentNode.scrollIntoView();
  }
  return errorElement;
};

// Scroll into the first form field with errors (if any).
const scrollIntoFirstError = (errors, visibleFields) => {
  if (errors) {
    for (const fieldIndex in visibleFields) {
      const field = visibleFields[fieldIndex];
      if (field in errors) {
        const errorElement = getErrorElement(field);
        if (errorElement) {
          return;  // Return on the first error field found.
        }
        // Check for nested elements:
        const nestedFields = Object.keys(errors[field]);
        for (const nestedIndex in nestedFields) {
          const nestedErrorElement = getErrorElement(`${field}.${nestedFields[nestedIndex]}`);
          if (nestedErrorElement) {
            return;
          }
        }
      }
    }
  }
};

const ScrollIntoFirstError = () => {
  const { dataType } = useContext(ParamsContext);
  const { errors } = useContext(DataContext);
  // TODO: getVisibleFields uses state.dataDetail.data, if I use DataContext, I must replace that too:
  const visibleFields = useSelector(state => getVisibleFields(dataType)(state));

  useEffect(() => {
    scrollIntoFirstError(errors, visibleFields);
  }, [errors, visibleFields]);

  return null;
};

export default memo(ScrollIntoFirstError);
