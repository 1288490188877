import {
  BATCH_UPLOAD_SUCCESS,
  BATCH_UPLOAD_FAILURE
} from '@constants/action-types';
import axios from 'axios';
import { downloadCSVFromURL } from '@actions/data-table-actions';
import { BASE_API_URL, getAPIRequestUrl } from '@constants/endpoints';

const uploadSuccess = payload => ({
  type: BATCH_UPLOAD_SUCCESS,
  payload
});

const uploadError = error => ({
  type: BATCH_UPLOAD_FAILURE,
  payload: error
});

export const uploadBatch = (upload, onProgress, onFail) => {
  const url = getAPIRequestUrl('batch');
  const formData = new FormData();
  formData.append('file', upload.file);
  formData.append('active', upload.active);
  formData.append('status', upload.status);
  if (upload.layerType) {
    formData.append('layerType', upload.layerType);
    formData.append('type', 2);
  } else
    if (upload.entityType) {
      formData.append('entityType', upload.entityType);
      formData.append('type', 3);
    }
  const config = { onUploadProgress: onProgress };
  const request = axios.post(url, formData, config);
  return dispatch =>
    request.then(
      data => dispatch(uploadSuccess(data)),
      error => {
        if (onFail) {
          onFail(error);
        }
        return dispatch(uploadError(error));
      }
    );
};

// Download a CSV template for the specified entity type:
export const downloadTemplate = type => {
  const url = `${BASE_API_URL}/batch/template/${type}/`;
  const filename = `${type}-template.csv`;
  return dispatch => dispatch(downloadCSVFromURL(url, filename, type));
};

export const validateBatch = (file, type, onValidationResults) => {
  const url = `${BASE_API_URL}/batch/validate/${type}/`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  const request = axios.post(url, formData);
  request.then(
    data => {
      onValidationResults(data?.data);
    },
    error => {
      onValidationResults({
        errors: [
          `Validation failed: ${error}`
        ]
      });
    }
  );
};

export const getSchema = (file, type, onError, onSchemaResults) => {
  const url = `${BASE_API_URL}/batch/schema/${type}/`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  const request = axios.post(url, formData);
  request.then(
    data => {
      onSchemaResults(data?.data?.rows);
    },
    error => {
      onError(`Error while retrieving fields: ${error}`);
    }
  );
};

export const convertToJSON = (file, type, fields, onError, onResults) => {
  const url = `${BASE_API_URL}/batch/tojson/${type}/`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fields', fields);
  formData.append('type', type);
  const request = axios.post(url, formData);
  request.then(
    data => {
      onResults(data?.data);
    },
    error => {
      onError(`Error while retrieving fields: ${error}`);
    }
  );
};
