/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { includes } from 'lodash';
import {
  getCalendarEntity,
  getDefaultEntity,
  getEntitiesListConfig,
  isWorkflowEnabled,
  getHeaderTabs
} from '@constants/config';
import { ENTITY_DATA_TYPE } from '@components/config/constants';
import { isSuperuser } from '@utils/permission-utils';
import { getFirstWorkflowMenuOption } from '@utils/workflow-utils';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import './header.scss';

const Links = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isCalendarEnabled = () => {
    // Check if we have defined a calendar entity:
    const calendarEntity = getCalendarEntity();
    if (calendarEntity) {
      const entities = getEntitiesListConfig();
      // If the calendar entity exists in the list of entities, the calendar is enabled.
      return includes(entities, calendarEntity);
    }
    return false;
  };

  const linkButton = (label, url) => {
    const { pathname } = location;
    const baseUrl = url.split('/')[1];
    const selected = pathname.startsWith(`/${baseUrl}`);
    return (
      <div styleName={`link-button ${selected ? 'selected' : ''}`}>
        <Link to={url}>{label}</Link>
      </div>
    );
  };

  const showModal = (label, text) => {
    dispatch(showConfirmationDialog(
      label,
      text
    ));
  };

  const modalButton = (label, text) => {
    return (
      <div styleName={'link-button'} onClick={() => showModal(label, text)} role="presentation">
        {label}
      </div>
    );
  };

  const headerTabs = () => {
    const tabs = getHeaderTabs();
    if (!tabs.length) {
      return (
        // default if tabs are not configured
        <div styleName="links-container">
          {linkButton('Map', '/map')}
          {linkButton('Library', `/library/${ENTITY_DATA_TYPE}/${getDefaultEntity()}`)}
          {isCalendarEnabled() && linkButton('Calendar', '/calendar')}
          {isWorkflowEnabled() && linkButton('Work', getFirstWorkflowMenuOption())}
          {linkButton('People', '/people/user')}
          {isSuperuser() && isWorkflowEnabled() && linkButton('Admin', '/admin')}
        </div>
      );
    }
    return (
      <div styleName="links-container">
        {tabs.map(tab => {
          if (tab.type === 'link' && tab.link && tab.label) {
            return linkButton(tab.label, tab.link);
          } else if (tab.type === 'library') {
            return linkButton(tab.label ? tab.label : 'Library', `/library/${ENTITY_DATA_TYPE}/${getDefaultEntity()}`);
          } else if (tab.type === 'admin' && isSuperuser() && isWorkflowEnabled()) {
            return linkButton(tab.label ? tab.label : 'Admin', '/admin');
          } else if (tab.type === 'calendar' && isCalendarEnabled()) {
            return linkButton(tab.label ? tab.label : 'Calendar', '/calendar');
          } else if (tab.type === 'workflow' && isWorkflowEnabled()) {
            return linkButton(tab.label ? tab.label : 'Work', getFirstWorkflowMenuOption());
          } else if (tab.type === 'modal') {
            return modalButton(tab.label, tab.text);
          }
          return '';
        })}
      </div>
    );
  };

  return (
    headerTabs()
  );
};

export default memo(Links);
