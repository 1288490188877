import { createSelector } from 'reselect';
import { optimizeEntitiesForList } from '@utils/entity-utils';
import { getAgencyAndEntityTypes } from '@selectors/shared-selector';

// Always use these constants for selectors, since using only a [] will generate a new instance.
const DEFAULT_CONFLICTS = [];
const DEFAULT_ENTITY = [];
const DEFAULT_OPPORTUNITIES = [];

const getConflicts = state => state.dataDetail?.data?.conflicts || DEFAULT_CONFLICTS;
const getEntity = state => state.dataDetail.data && [state.dataDetail.data.entity] || DEFAULT_ENTITY;
const getOpportunities = state => state.dataDetail?.data?.opportunities || DEFAULT_OPPORTUNITIES;

export const optimizeOverlapConflictsForListSelector = createSelector([getConflicts, getAgencyAndEntityTypes], optimizeEntitiesForList);
export const optimizeOverlapOpportunitiesForListSelector = createSelector([getOpportunities, getAgencyAndEntityTypes], optimizeEntitiesForList);
export const optimizeEntityForListSelector = createSelector([getEntity, getAgencyAndEntityTypes], optimizeEntitiesForList);

export const getOverlapsSelector = createSelector(
  [optimizeOverlapConflictsForListSelector, optimizeOverlapOpportunitiesForListSelector],
  (conflicts, opportunities) => [].concat(conflicts, opportunities)
);
