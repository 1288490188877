/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-danger */
import React, { memo, useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { authLogin, authSetUserField } from '@actions/auth-actions';
import { dotmapsBlack60, secondaryColor } from '@constants/colors';
import { loginStyles } from '@constants/mui-theme';
import { Button } from '@mui';
import TextField from '@material-ui/core/TextField';
import { clearAuth } from '@utils/auth-utils';
import './layouts/not-authenticated/not-authenticated.scss';

const Login = () => {
  const location = useLocation();
  const {
    activeDirectory: { disclaimer, enabled, hint, icon, label, url },
    city,
    defaultUrl,
    isDemo,
    loginFooter,
    siteLoaded
  } = useSelector(state => state.config);
  const { errors, user } = useSelector(state => state.auth);
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    clearAuth();
  }, []);

  const onEmailChange = event => {
    dispatch(authSetUserField(event.target.name, event.target.value));
  };

  const onPasswordChange = event => {
    setPassword(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    dispatch(authLogin({ ...user, password }, location, defaultUrl));
  };

  if (!siteLoaded) {
    return null;
  }

  const emptyPassword = R.isEmpty(password);
  const emptyEmail = R.isEmpty(user.username);
  const emailLoginDisabled = emptyEmail || emptyPassword;
  return (
    <div styleName="not-authenticated-container">
      <form action="/" onSubmit={onSubmit}>
        <div styleName="auth-heading">Login</div>
        {enabled &&
          <div styleName="auth-section">
            {disclaimer && (
              <div
                style={{ display: 'inline' }}
                dangerouslySetInnerHTML={{__html: disclaimer}}
              />
            )}
            {!disclaimer && (
              <div>
                All employees should login with{' '}
                <Link to={hint.url} target="_blank" >
                  <div
                    style={{ display: 'inline' }}
                    dangerouslySetInnerHTML={{__html: hint.label}}
                  />
                </Link>
                {' '}using their existing credentials.
              </div>
            )}
            <Button
              fullWidth
              startIcon={<img src={icon} alt={label} style={{ margin: '10px 16px 10px 16px' }} />}
              href={url}
              style={{
                alignItems: 'center',
                border: '1px solid #8C8C8C',
                borderRadius: '2px',
                backgroundColor: '#FFFFFF',
                justifyContent: 'center',
                flexDirection: 'row',
                display: 'flex',
                height: '40px',
                margin: '0'
              }}
            >
              <div style={{
                alignItems: 'center',
                color: dotmapsBlack60,
                display: 'flex',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '500',
                justifyContent: 'center',
                lineHeight: '24px',
                paddingRight: '1rem',
                textTransform: 'uppercase',
                textAlign: 'center'
              }}>
                {label}
              </div>
            </Button>
            <div styleName="or-rule">
              <div styleName="text"> or </div>
              <div styleName="rule" />
            </div>
          </div>
        }
        {isDemo &&
          <div styleName="auth-demo">
            This is a prototype version of Coordinate<br />
            for test and demonstration purposes only.
          </div>
        }
        {errors && errors.non_field_errors && (
          <div styleName="not-authenticated-form-error-container">
            <div
              dangerouslySetInnerHTML={{__html: errors.non_field_errors}}
              styleName="not-authenticated-form-error"
            />
          </div>
        )}
        <div styleName="not-authenticated-form-text-field">
          <TextField
            fullWidth
            id="username"
            label="Email"
            name="username"
            error={errors?.username?.length > 0}
            helperText={errors?.username}
            onChange={onEmailChange}
            value={user.username}
          />
        </div>
        <div styleName="not-authenticated-form-text-field">
          <TextField
            fullWidth
            id="password"
            label="Password"
            type="password"
            name="password"
            onChange={onPasswordChange}
            error={errors?.password?.length > 0}
            helperText={errors?.password}
            value={password}
          />
        </div>
        {city === 'seattle' &&
          <div styleName="auth-section-horizontal">
            <div>
              <Link style={loginStyles.links} to={'/password'}>Forgot password?</Link>
            </div>
            <div>
              <Button
                color="primary"
                fullWidth
                disabled={emailLoginDisabled}
                size="large"
                type="submit"
                variant="contained"
              >
                LOGIN WITH EMAIL
              </Button>
            </div>
          </div>
        }
        {city !== 'seattle' &&
          <div styleName="auth-section">
            <div styleName="auth-section-left">
              <Link
                style={{...loginStyles.links, color: secondaryColor}}
                to={'/password'}
              >
                Forgot your password?
              </Link>
            </div>
            <div>
              <Button
                color="primary"
                fullWidth
                disabled={emailLoginDisabled}
                size="large"
                type="submit"
                variant="contained"
              >
                LOG IN
              </Button>
            </div>
          </div>
        }
        {loginFooter && <div styleName="auth-footer" dangerouslySetInnerHTML={{__html: loginFooter}} />}
      </form>
    </div>
  );
};

export default memo(Login);
