import React, { memo, useCallback, useContext, useMemo } from 'react';

import { Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BatchContext from '@components/batch/batch-upload-edit/batch-context';
import FormTab from '@components/entity/info/drawer/form/form-tab';
import NonFieldErrors from '@components/entity/info/drawer/form/non-field-errors';
import ScrollIntoFirstError from '@components/entity/info/drawer/form/scroll-into-first-error';
import Map from '@components/entity/info/map';
import ParamsContext from '@components/entity/info/params-context';

import { paper } from '@constants/mui-theme';

import DataContext from '@forms/data-context';

import EditScheduleDialog from '@shared/dialogs/edit-schedule-dialog';
import BulkScheduleDialog from '@shared/dialogs/bulk-schedule-dialog';
import ViewScheduleDialog from '@shared/dialogs/view-schedule-dialog';

import {
  convertCSVDataForContext,
  convertCSVErrorsForContext
} from '@utils/batch-utils';
import { onScroll } from '@utils/form-utils';
import { routerWillLeave } from '@utils/router-utils';

import './content-form.scss';
import styles from '../../forms/forms.scss';  // eslint-disable-line css-modules/no-unused-class

const ContentForm = () => {
  const dispatch = useDispatch();
  const { batch: { entity, errors, rows }, rowId } = useContext(BatchContext);
  const { details } = useSelector(state => state.config);
  const dataType = useMemo(() => entity, [entity]);
  const data = useMemo(() => rows.find(row => row.id === rowId), [rowId, rows]);

  const contextValue = useMemo(() => ({ dataType, id: rowId }), [dataType, rowId]);

  const dataContextValue = useMemo(() => ({
    data: convertCSVDataForContext(data, entity),
    errors: convertCSVErrorsForContext(data, errors)
  }), [data, entity, errors]);
  const tabs = useMemo(() => details?.[dataType]?.tabs.filter(tab => !tab.hide_internal), [dataType, details]);
  const onFormScroll = useCallback(() => onScroll(tabs), [tabs]);

  return (
    <div styleName="batch-upload-edit-content-form">
      <div styleName="batch-upload-edit-content-form-container">
        <div className={styles.drawerFormContainer} key="drawer" style={paper}>
          <ParamsContext.Provider value={contextValue}>
            <DataContext.Provider value={dataContextValue}>
              <div id="form-container" onScroll={onFormScroll} className={styles.formContainer}>
                <ScrollIntoFirstError />
                <NonFieldErrors />
                {tabs?.map(tab => <FormTab key={tab.id} tab={tab} />)}
                <BulkScheduleDialog />
                <ViewScheduleDialog />
                <EditScheduleDialog />
                {/*
                  TODO: disabled for now
                  <Prompt message={nextLocation => routerWillLeave(dispatch, nextLocation)} />
                */}
              </div>
            </DataContext.Provider>
          </ParamsContext.Provider>
        </div>
        <Map />
      </div>
    </div>
  );
};

export default memo(ContentForm);
