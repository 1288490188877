import React, { memo, useState } from 'react';
import LoadingIndicator from '@components/details/loading-indicator';
import LocationContext from '@components/entity/info/location-context';
import Drawer from '@components/entity/info/drawer';
import Map from '@components/entity/info/map';
import './info.scss';

const Info = () => {
  const [activeSegmentId, setActiveSegmentId] = useState(null);
  const [activeType, setActiveType] = useState(null);
  const [activeStart, setActiveStart] = useState(null);
  const [activeEnd, setActiveEnd] = useState(null);
  const [geometryLocked, setGeometryLocked] = useState(false);

  const contextValue = {
    activeSegmentId,
    setActiveSegmentId,
    activeType,
    setActiveType,
    activeStart,
    setActiveStart,
    activeEnd,
    setActiveEnd,
    geometryLocked,
    setGeometryLocked
  };

  return (
    <div styleName="info-container">
      <LoadingIndicator />
      <LocationContext.Provider value={contextValue}>
        <Drawer />
        <Map />
      </LocationContext.Provider>
    </div>
  );
};

export default memo(Info);
