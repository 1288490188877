//
// Utility functions to retrieve backend settings.
//
import * as R from 'ramda';
import { includes } from 'lodash';
import { getConfigPath, getConfigPathOr } from '@utils/config-utils';
import { getPortalPath } from '@utils/dashboard-utils';

export const getEntitiesListConfig = () => getConfigPathOr(['entities']);

export const getEntityType = entityTypeName => {
  const types = getConfigPathOr(['entity_types']);
  return types.find(type => type.name === entityTypeName);
};

export const getEntityTypeLabel = entityTypeName => {
  const types = getConfigPathOr(['entity_types']);
  const entityType = types.find(type => type.name === entityTypeName);
  if (entityType) {
    return entityType.display_name;
  }
  return (entityTypeName || '').replace(/_/g, ' ');
};

export const getExternalIdLabel = entityTypeName => {
  const types = getConfigPathOr(['entity_types']);
  const entityType = types.find(type => type.name === entityTypeName);
  return entityType?.config?.fields?.external_id?.serializer_props?.label || 'External ID';
};

export const getGroupTypesConfig = () => getConfigPathOr(['group_types']);

export const getGanttConfig = () => getConfigPathOr(['gantt']);

const getDashboardConfigPath = (paths, portal) => ['dashboard', portal || getPortalPath(), ...paths];

const getDashboardConfigPathOr = (paths, portal) => getConfigPathOr(getDashboardConfigPath(paths, portal));

export const getDashboardConfig = () => getConfigPathOr(['dashboard']);

export const getDashboardBackgroundColor = () => getDashboardConfigPathOr(['color']);

export const getDashboardMenuConfig = () => getDashboardConfigPathOr(['menu']);

// Return the config inside the dashboard config from the supplied config object:
export const getDashboardPathFromConfig = (config, paths, portal) => R.pathOr({}, getDashboardConfigPath(paths, portal), config);

// Edit details page config:
export const getDetailsConfig = () => getConfigPathOr(['details']);

export const getGroupDetailsConfig = groupType => getConfigPathOr(['details', 'group', groupType]);

export const getPriorityConfig = () => getConfigPathOr(['priorities']);

const getSegmentConfig = dataType => {
  const tabs = getConfigPathOr(['details', dataType, 'tabs']);
  const locationsTab = tabs.find(tab => tab.id === 'locations');
  return locationsTab.segment_fields;
};

export const displaySegmentField = (dataType, field) => includes(getSegmentConfig(dataType), field);

const getSegmentTypesConfig = dataType => {
  const tabs = getConfigPathOr(['details', dataType, 'tabs']);
  const locationsTab = tabs.find(tab => tab.id === 'locations');
  return locationsTab.segment_types;
};

export const displaySegmentType = (dataType, type) => includes(getSegmentTypesConfig(dataType), type);

export const getSegmentFieldConfig = (dataType, type) => {
  const segmentConfig = getConfigPathOr(['details', dataType, 'form', 'columns', 'segments']);
  if (type in segmentConfig) {
    return segmentConfig[type];
  }
  return {};
};

// Map config:
export const getDefaultZoom = () => getConfigPath(['map', 'zoom', 'defaultZoom']);

export const getMarkerVisibleZoom = () => getConfigPath(['map', 'zoom', 'markerVisibleZoom']);

export const getMarkerAlwaysVisibleZoom = () => getConfigPath(['map', 'zoom', 'markerAlwaysVisibleZoom']);

export const getZoomIndexes = zoom => getConfigPath(['map', 'clusters', 'zoomIndexes', zoom]);

export const getLegendItems = () => getConfigPath(['map', 'legend', 'items']);

export const getMapToolbarConfig = () => getConfigPath(['map', 'toolbar']);

export const getDefaultMapStylers = () => getConfigPath(['map', 'mapStylers', 'default']);
export const getPoiMapStylers = () => getConfigPath(['map', 'mapStylers', 'poisOn']);

// Entity Config
export const getDefaultEntity = () => getConfigPathOr(['default_entity']);
export const getCalendarEntity = () => getConfigPathOr(['calendar_entity']);

// Tray config:
export const getTrayConfig = () => getConfigPathOr(['tray']);

export const getDetailCardConfig = () => getConfigPathOr(['detailCardConfig']);

export const getMenuItemConfig = () => getConfigPathOr(['menuItemConfig']);

export const getGroupTrayConfig = () => getConfigPathOr(['detailTrayConfig', 'group', 'group_type']);

export const getListItemConfig = () => getConfigPathOr(['list-item']);

// Filters:
export const getFiltersGlobal = () => ({ ...getConfigPathOr(['filters', 'global']) });
export const getFiltersConfig = () => ({ ...getConfigPathOr(['filters', 'filters']) });
export const getFiltersMap = () => ({ ...getConfigPathOr(['filters', 'map']) });

// Data table:
export const getDataTableConfig = portal => getDashboardConfigPathOr(['datatable', 'config'], portal);
export const getDataTableActions = () => getDashboardConfigPathOr(['datatable', 'actions']);

// Activity Log:
export const getActivityLogTooltipsConfig = () => getConfigPathOr(['logs', 'tooltips']);

// Settings:
export const getMenuSettingsConfig = () => getConfigPathOr(['settings', 'menu']);
export const getEmailSettingsConfig = () => getConfigPathOr(['settings', 'email']);
export const getNotificationsSettingsConfig = () => getConfigPathOr(['settings', 'notifications']);

// Global config:
export const getGlobalAliases = () => getConfigPathOr(['global', 'aliases']);

export const getScheduleEnabledList = () => getConfigPathOr(['schedule', 'enabled']);

export const getAttachmentEnabledList = () => getConfigPathOr(['attachments', 'enabled']);

export const getCategoryTypes = () => getConfigPathOr(['category_types']);

export const getOwnersAllowAllUsers = () => getConfigPathOr(['global', 'owners_allow_all_users']);
export const getAllowAllUsersComment = () => getConfigPathOr(['global', 'allow_all_users_comment']);

export const isNewBatch = () => getConfigPathOr(['global', 'batch']) === 'default';
export const isMilitaryTime = () => getConfigPathOr(['global', 'time']) === '24';
export const isTimeEnabled = () => getConfigPathOr(['global', 'display_time_component']) === 'yes';

// Workflow:
export const isWorkflowEnabled = () => getConfigPathOr(['workflow', 'enabled']);
export const getWorkflowCustomFieldSections = () => getConfigPathOr(['workflow', 'custom-fields', 'sections']);
export const getWorkflowCustomFieldTypes = () => {
  const fieldTypes = getConfigPathOr(['workflow', 'custom-fields', 'field-types']);
  // Return field types as an object with the field type id as a key:
  const fieldTypesById = {};
  fieldTypes.forEach(fieldType => {
    fieldTypesById[fieldType.id] = fieldType.name;
  });
  return fieldTypesById;
};
export const getWorkflowCustomFieldTypesAsDataSource = () => {
  const fieldTypes = getConfigPathOr(['workflow', 'custom-fields', 'field-types']);
  const fieldTypesById = {};
  fieldTypes.forEach(fieldType => {
    // File upload field type must be skipped, since attachments are a hardcoded
    // field for tasks, and a core field for activities.
    if (fieldType.name !== 'File upload') {
      fieldTypesById[fieldType.id] = {
        id: fieldType.id,
        name: fieldType.name
      };
    }
  });
  return fieldTypesById;
};

// Header
export const getHeaderTabs = () => getConfigPathOr(['header', 'tabs']);
