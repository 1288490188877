//
// This component renders a date range on a form (i.e. start and end date fields).
//
/* eslint-disable max-depth */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  errorDataField,
  updateDataField
} from '@actions/data-detail-actions';

import { REQUIRED_ERROR } from '@components/forms/constants';

import { getEntityTypeLabel } from '@constants/config';
import { detailEdit } from '@constants/mui-theme';

import DataContext from '@forms/data-context';

import { Checkbox } from '@mui';

import { getMetadata } from '@selectors/forms-selector';

import FormattedDatePicker from '@shared/formatted-date-picker';
import MomentTimePicker from '@shared/moment-time-picker';

import './forms.scss';

const PICKER_PROPS = { floatingLabelStyle: { whiteSpace: 'nowrap' } };

const getTimeLabel = label => {
  const text = label?.replace('date', 'time')?.replace('Date', 'Time');
  if (text?.search('time') < 0 && text?.search('Time') < 0) {
    return `${text} time`;
  }
  return text;
};

const clearError = (errors, dateField, timeField, dateValue, timeValue, onError) => {
  // Check if the "this field may not be null" error is set for the "date" input box:
  if (errors[dateField]?.includes(REQUIRED_ERROR)) {
    // If the "date" value is now set, clear the error:
    if (dateValue) {
      onError(dateField, null);
    }
    // If the "time" value is also set, clear the error there too:
    if (timeValue) {
      onError(timeField, null);
    } else {
      // But if the "date" field has an error and the "time" value is not set,
      // manually apply the null error on the time input box.
      //
      // This is needed since the date/time combo is a single field on the backend,
      // to use it on the frontend, we must manually set it.
      onError(timeField, REQUIRED_ERROR);
    }
  }
};

// Only set the date in the state if it's different from the current value.
const dateSetter = (current, setValue, newValue) => {
  if ((!newValue && !current) || newValue?.isSame(current, 'minute')) {
    return;
  }
  setValue(newValue);
};

const DateRange = ({ fieldNames, isPublic, readOnly }) => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { data, errors } = useContext(DataContext);
  // TODO: getMetadata uses state.dataDetail
  const metadata = useSelector(state => getMetadata(dataType, isPublic)(state));
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [allDay, setAllDay] = useState(null);
  const [startInit, setStartInit] = useState(false);
  const [endInit, setEndInit] = useState(false);

  const dataTypeDisplayName = useMemo(() => getEntityTypeLabel(dataType), [dataType]);

  const setStartDateWrapper = useCallback(value => dateSetter(startDate, setStartDate, value), [startDate, setStartDate]);
  const setStartTimeWrapper = useCallback(value => dateSetter(startTime, setStartTime, value), [startTime, setStartTime]);
  const setEndDateWrapper = useCallback(value => dateSetter(endDate, setEndDate, value), [endDate, setEndDate]);
  const setEndTimeWrapper = useCallback(value => dateSetter(endTime, setEndTime, value), [endTime, setEndTime]);

  // Set all_day into this component's state when loaded the first time.
  useEffect(() => {
    if (allDay === null) {
      setAllDay(data.all_day);
    }
  }, [allDay, data.all_day]);

  // Also set start/end dates.
  useEffect(() => {
    if (data.start_date && startDate === null && !startInit) {
      setStartDateWrapper(moment(data.start_date));
      setStartTimeWrapper(moment(data.start_date));
      setStartInit(true);
    }
  }, [data.start_date, setStartDateWrapper, setStartTimeWrapper, startDate, startInit, setStartInit]);

  useEffect(() => {
    if (data.end_date && endDate === null && !endInit) {
      setEndDateWrapper(moment(data.end_date));
      setEndTimeWrapper(moment(data.end_date));
      setEndInit(true);
    }
  }, [data.end_date, setEndDateWrapper, setEndTimeWrapper, endDate, endInit, setEndInit]);

  const onChange = useCallback((field, value) => {
    if (value === null) {
      if (data[field]) {
        dispatch(updateDataField(field, null));
      }
    } else
      if ((!data[field] || !value.isSame(data[field], 'day,hour,minute')) && value.isValid()) {
        dispatch(updateDataField(field, value));
      }
  }, [data, dispatch]);

  const onBoolChange = useCallback((field, value) => {
    if (value !== data[field]) {
      dispatch(updateDataField(field, value));
    }
  }, [data, dispatch]);

  const onError = useCallback((field, error) => {
    if (error === null && typeof errors[field] === 'undefined') {
      return;
    }
    if (!errors[field]?.includes(error)) {  // Set new error, only if it changed.
      dispatch(errorDataField(field, error, false));
    }
  }, [dispatch, errors]);

  // Clear (or set) start/end date/time errors when they are filled.
  useEffect(() => {
    clearError(errors, 'start_date', 'start_time', startDate, startTime, onError);
    clearError(errors, 'end_date', 'end_time', endDate, endTime, onError);
  }, [endDate, endTime, errors, onError, startDate, startTime]);

  // Convert date/time combo date into a moment() object.
  const getMomentDate = useCallback((date, time) => {
    if ((!fieldNames.includes('all_day') || allDay) && date) {
      return moment(date);
    } else if (fieldNames.includes('all_day') && !allDay && date && time) {
      return moment(date).hours(time.hours())
        .minutes(time.minutes());
    }
    return null;
  }, [allDay, fieldNames]);

  const momentStart = useMemo(() => getMomentDate(startDate, startTime), [getMomentDate, startDate, startTime]);
  const momentEnd = useMemo(() => getMomentDate(endDate, endTime), [getMomentDate, endDate, endTime]);

  // If start/end dates are set, validate that the end date is not before the start one.
  useEffect(() => {
    if (momentStart && momentEnd) {
      if (momentEnd.isBefore(momentStart)) {
        // We only set the error message on the 'end date' field:
        onError('end_date', `${dataTypeDisplayName} must end after it starts`);
        onError('end_time', ' ');
      } else {
        onError('end_date', null);
        onError('end_time', null);
      }
    }
  }, [dataTypeDisplayName, momentStart, momentEnd, onError]);

  // Set new values on the store if there are no errors:
  useEffect(() => {
    if (!errors.start_date && !errors.start_time && startInit) {
      onChange('start_date', momentStart);
    }
  }, [errors.start_date, errors?.start_time, momentStart, onError, onChange, startInit]);

  useEffect(() => {
    if (!errors.end_date && !errors.end_time && endInit) {
      onChange('end_date', momentEnd);
    }
  }, [errors?.end_date, errors?.end_time, momentEnd, onError, onChange, endInit]);

  const onCheck = useCallback(event => {
    const name = event.target.name;
    const newValue = !data[name];
    onBoolChange(name, newValue);
    setAllDay(newValue);
    if (newValue) {
      setStartTimeWrapper(null);
      setEndTimeWrapper(null);
      onError('start_time', null);
      onError('end_time', null);
    }
  }, [data, onBoolChange, onError, setAllDay, setStartTimeWrapper, setEndTimeWrapper]);

  const onStartDateChange = useCallback(value => {
    if (value) {
      if (endDate && fieldNames.includes('end_date')) {
        setStartDateWrapper(moment(value).set({ hour: '00', minute: '00' }));
        onError('start_date', null);
      } else {
        setStartDateWrapper(moment(value).set({ hour: '00', minute: '00' }));
        onError('start_date', null);

        // Setting the startDate makes the "end date" date picker to have the
        // minDate property set (thus the start date will appear on the end
        // date input bux) thus we must also set it on the component's state:
        setEndDateWrapper(moment(value).set({ hour: '00', minute: '00' }));
        onError('end_date', null);
        setEndInit(true);
      }
    } else {
      setStartDateWrapper(null);
    }
    setStartInit(true);
  }, [endDate, fieldNames, onError, setEndDateWrapper, setStartDateWrapper]);

  const onEndDateChange = useCallback(value => {
    if (value) {
      setEndDateWrapper(moment(value).set({ hour: '00', minute: '00' }));
      onError('end_date', null);
    } else {
      setEndDateWrapper(null);
    }
    setEndInit(true);
  }, [onError, setEndDateWrapper]);

  const onStartTimeChange = useCallback(value => {
    setStartTimeWrapper(value);
    if (value) {
      onError('start_time', null);
    }
  }, [onError, setStartTimeWrapper]);

  const onEndTimeChange = useCallback(value => {
    setEndTimeWrapper(value);
    if (value) {
      onError('end_time', null);
    }
  }, [onError, setEndTimeWrapper]);

  const timeVisible = useMemo(() => fieldNames.includes('all_day') && !data.all_day, [data.all_day, fieldNames]);
  const startStyle = useMemo(() => timeVisible ? detailEdit.columnStyles.startDateRangeDate : detailEdit.columnStyles.col50, [timeVisible]);
  const endStyle = useMemo(() => timeVisible ? detailEdit.columnStyles.endDateRangeDate : detailEdit.columnStyles.col50, [timeVisible]);

  // Memoize some picker component properties.
  const startDateError = useMemo(() => Array.isArray(errors.start_date) ? errors.start_date.join(', ') : null, [errors.start_date]);
  const startTimeError = useMemo(() => Array.isArray(errors.start_time) ? errors.start_time.join(', ') : null, [errors.start_time]);
  const endDateError = useMemo(() => Array.isArray(errors.end_date) ? errors.end_date.join(', ') : null, [errors.end_date]);
  const endTimeError = useMemo(() => Array.isArray(errors.end_time) ? errors.end_time.join(', ') : null, [errors.end_time]);

  const startDateValue = useMemo(() => startDate ? startDate.toDate() : null, [startDate]);
  const endDateValue = useMemo(() => endDate ? endDate.toDate() : null, [endDate]);

  const startDateLabel = useMemo(
    () => `${metadata.start_date?.label}${metadata.start_date?.required ? ' *' : ''}`,
    [metadata.start_date]
  );
  const startTimeLabel = useMemo(
    () => `${getTimeLabel(metadata.start_date?.label)}${metadata.start_date?.required ? ' *' : ''}`,
    [metadata.start_date]
  );
  const endDateLabel = useMemo(
    () => `${metadata.end_date?.label}${metadata.end_date?.required ? ' *' : ''}`,
    [metadata.end_date]
  );
  const endTimeLabel = useMemo(
    () => `${getTimeLabel(metadata.end_date?.label)}${metadata.end_date?.required ? ' *' : ''}`,
    [metadata.end_date]
  );

  const startDateProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      clearable: !metadata.start_date?.required,
      disabled: metadata.start_date?.read_only || readOnly,
      errorText: startDateError,
      floatingLabelText: startDateLabel,
      id: 'start_date',
      maxDate: fieldNames.includes('end_date') && endDate ? endDate : null,
      name: 'start_date',
      onChange: onStartDateChange,
      value: startDateValue
    }),
    [endDate, fieldNames, metadata.start_date, onStartDateChange, readOnly, startDateError, startDateLabel, startDateValue]
  );

  const endDateProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      clearable: !metadata.start_date?.required,
      disabled: metadata.end_date?.read_only || readOnly,
      errorText: endDateError,
      floatingLabelText: endDateLabel,
      id: 'end_date',
      minDate: startDate || null,
      name: 'end_date',
      onChange: onEndDateChange,
      value: endDateValue
    }),
    [endDateError, endDateLabel, endDateValue, metadata.end_date, metadata.start_date, onEndDateChange, readOnly, startDate]
  );

  const startTimeProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      disabled: metadata.start_date?.read_only || readOnly,
      errorText: startTimeError,
      floatingLabelText: startTimeLabel,
      fullWidth: true,
      id: 'start_time',
      name: 'start_time',
      onChange: onStartTimeChange,
      value: startTime || null,
      format: metadata?.start_date?.format
    }),
    [metadata.start_date, onStartTimeChange, readOnly, startTime, startTimeError, startTimeLabel]
  );

  const endTimeProps = useMemo(
    () => ({
      ...PICKER_PROPS,
      disabled: metadata.end_date?.read_only || readOnly,
      errorText: endTimeError,
      floatingLabelText: endTimeLabel,
      fullWidth: true,
      id: 'end_time',
      name: 'end_time',
      onChange: onEndTimeChange,
      value: endTime || null,
      format: metadata?.end_date?.format
    }),
    [endTime, endTimeError, endTimeLabel, metadata.end_date, onEndTimeChange, readOnly]
  );

  const checkbox = useMemo(() => (
    <div
      styleName={metadata.all_day?.style || 'col100'}
      style={{ margin: '0.5rem 0 0 2rem', paddingBottom: '0.5rem' }}
    >
      <Checkbox
        checked={data.all_day}
        disabled={readOnly}
        name="all_day"
        label={metadata.all_day?.label}
        onChange={onCheck}
        size="small"
      />
    </div>
  ), [data.all_day, metadata.all_day, onCheck, readOnly]);

  if (Boolean(metadata.all_day?.linebreak)) {
    return (
      <div>
        <FormattedDatePicker {...startDateProps} fullWidth style={{...detailEdit.columnStyles.DateTimeRange}} />
        {timeVisible && <MomentTimePicker {...startTimeProps} style={{...detailEdit.columnStyles.DateTimeRange}} />}
        {fieldNames.includes('end_date') && <FormattedDatePicker {...endDateProps} fullWidth style={{...detailEdit.columnStyles.DateTimeRange}} />}
        {fieldNames.includes('end_date') && timeVisible && <MomentTimePicker {...endTimeProps} style={{...detailEdit.columnStyles.DateTimeRange}} />}
        {fieldNames.includes('all_day') && checkbox}
      </div>
    );
  }
  return (
    <div>
      <FormattedDatePicker {...startDateProps} fullWidth={!timeVisible} style={{...startStyle}} styleName="input-field" />
      {timeVisible && <MomentTimePicker {...startTimeProps} style={{...detailEdit.columnStyles.startDateRangeTime}} styleName="input-field" />}
      {fieldNames.includes('end_date') && timeVisible &&
        <MomentTimePicker
          {...endTimeProps}
          style={{...detailEdit.columnStyles.endDateRangeTime}}
          styleName="input-field"
        />
      }
      {fieldNames.includes('end_date') &&
        <FormattedDatePicker {...endDateProps} fullWidth={!timeVisible} style={{...endStyle}} styleName="input-field" />
      }
      {fieldNames.includes('all_day') && checkbox}
    </div>
  );
};

DateRange.propTypes = {
  fieldNames: PropTypes.array,
  isPublic: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default memo(DateRange);
