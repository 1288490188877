import React, { memo, useContext } from 'react';

import DataContext from '@forms/data-context';

import '../../../../forms/forms.scss';

const NonFieldErrors = () => {
  const { errors: { non_field_errors } } = useContext(DataContext);
  if (non_field_errors) {
    return (
      <div styleName="detail-error">
        {non_field_errors.map((msg, index) => <div key={index}>{msg}</div>)}
      </div>
    );
  }
  return null;
};

export default memo(NonFieldErrors);
