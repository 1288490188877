/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';

import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import { openDashboardDialog } from '@actions/dashboard-actions';
import {
  clearSchedule,
  cloneSegment,
  deleteSegment,
  swapSegmentFields,
  updatePolygon,
  updateSegmentEndpoints,
  updateSegmentField
} from '@actions/data-detail-actions';

import LocationContext from '@components/entity/info/location-context';

import { colors } from '@constants/colors';
import { displaySegmentField, displaySegmentType, getEntityTypeLabel, getSegmentFieldConfig } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { detailEdit, formStyles } from '@constants/mui-theme';

import DataTypesSelect from '@forms/data-types-select';
import InputAutocomplete from '@forms/input-autocomplete';
import ShapeTypeTooltip from '@forms/shape-type-tooltip';
import SnapTooltip from '@forms/snap-tooltip';

import { Button, Checkbox, Icon, IconButton } from '@mui';

import {
  getSegmentTitle,
  renderScheduleString,
  renderWarning,
  showMissingDatesWarning,
  showScheduleExceedsWarning
} from '@utils/segment-schedule/common';

import './segment-list.scss';

const MAX_TITLE_LEN = 50;
const MAX_DESCRIPTION_LEN = 200;

const SegmentEditListItem = ({
  checkAddressErrors,
  data,
  dataType,
  onClick,
  readOnly
}) => {
  const segments = useMemo(() => data?.segments || [], [data?.segments]);
  const dispatch = useDispatch();
  const {
    activeSegmentId,
    activeStart,
    activeEnd,
    activeType,
    setActiveEnd,
    setActiveStart,
    setActiveType
  } = useContext(LocationContext);

  const segment = useMemo(() => segments?.find(seg => seg.id === activeSegmentId), [activeSegmentId, segments]);

  const onShapeTypeChange = useCallback(
    (event, type) => {
      setActiveType(type);
      if (type === 'Point') {
        dispatch(updateSegmentEndpoints({ start: activeStart, end: null }, activeSegmentId, segment.override_street_centering));
        setActiveEnd(null);
      }
      if (type === 'LineString' && activeEnd) {
        dispatch(updateSegmentEndpoints({ start: activeStart, end: activeEnd }, activeSegmentId, segment.override_street_centering));
      }
      if (type === 'Polygon') {
        dispatch(updateSegmentEndpoints({ start: null, end: null }, activeSegmentId, segment.override_street_centering));
      }
    },
    [activeStart, activeEnd, activeSegmentId, dispatch, segment.override_street_centering, setActiveEnd, setActiveType]
  );

  const onFieldChange = useCallback(
    (name, value) => {
      dispatch(updateSegmentField(segment.id, name, value));
      if (name === 'override_street_centering' && segment.id === activeSegmentId) {
        dispatch(updateSegmentEndpoints({ start: activeStart, end: activeEnd }, activeSegmentId, value));
      }
    },
    [activeSegmentId, activeStart, activeEnd, dispatch, segment.id]
  );

  const onActiveSegmentChanged = useCallback(
    endPoints => {
      const newEndPoints = { ...{ start: activeStart, end: activeEnd }, ...endPoints };
      setActiveStart(newEndPoints.start);
      setActiveEnd(newEndPoints.end);
      dispatch(updateSegmentEndpoints(newEndPoints, activeSegmentId, segment.override_street_centering, 'segmentForm'));
    },
    [activeSegmentId, activeStart, activeEnd, dispatch, segment.override_street_centering, setActiveEnd, setActiveStart]
  );

  const onFieldError = useCallback(
    (name, error) => {
      dispatch(updateSegmentField(segment.id, name, null, error));
    },
    [dispatch, segment.id]
  );

  const onCheck = useCallback(
    event => {
      const name = event.target.name;
      onFieldChange(name, !segment[name]);
    },
    [onFieldChange, segment]
  );

  const onFromChange = useCallback(value => onFieldChange('display_from', value), [onFieldChange]);

  const onToChange = useCallback(value => onFieldChange('display_to', value), [onFieldChange]);

  const onTitleChange = useCallback((event, value) => onFieldChange('title', value.substring(0, MAX_TITLE_LEN)), [onFieldChange]);

  const onDescriptionChange = useCallback((event, value) => onFieldChange('description', value.substring(0, MAX_DESCRIPTION_LEN)), [onFieldChange]);

  const onDirectionChange = useCallback((event, index, value) => onFieldChange('direction', value), [onFieldChange]);

  const onImpactChange = useCallback((event, index, value) => onFieldChange('impact_type', value), [onFieldChange]);

  const onFromGeocode = useCallback(latLng => onActiveSegmentChanged({ start: latLng }), [onActiveSegmentChanged]);

  const onToGeocode = useCallback(latLng => onActiveSegmentChanged({ end: latLng }), [onActiveSegmentChanged]);

  const onFromGeocodeError = useCallback(status => onFieldError('display_from', status), [onFieldError]);

  const onToGeocodeError = useCallback(status => onFieldError('display_to', status), [onFieldError]);

  const swapAddresses = useCallback(
    () => {
      dispatch(swapSegmentFields(segment.id, 'display_from', 'display_to'));
      // Swap start/end too:
      setActiveStart(activeEnd);
      setActiveEnd(activeStart);
    },
    [activeStart, activeEnd, dispatch, segment.id, setActiveEnd, setActiveStart]
  );

  const onClone = useCallback(() => {
    dispatch(cloneSegment(segment.id));
  }, [dispatch, segment.id]);

  const onDeleteSegment = useCallback(() => {
    dispatch(deleteSegment(segment.id));
  }, [dispatch, segment.id]);

  const editSchedule = useCallback(
    () => {
      const params = {
        id: segment.id,
        start_date: data.start_date,
        end_date: data.end_date
      };
      dispatch(openDashboardDialog(dialog.EDIT_SCHEDULE, params));
    },
    [data.start_date, data.end_date, dispatch, segment.id]
  );

  const onClearSchedule = useCallback(() => {
    dispatch(clearSchedule(segment.id));
  }, [dispatch, segment.id]);

  const clearPolygon = useCallback(() => {
    dispatch(updatePolygon(segment.id, null, 'map'));
  }, [dispatch, segment.id]);

  const renderShapeType = useCallback(() => {
    // It could be simpler to have in this method a single RadioButtonGroup
    // and only switch on/off the polygon RadioButton, however, for
    // some reason (some code inside RadioButtonGroup), we cannot
    // add conditional JSX within RadioButtonGroups, thus we
    // have to separate this in two.
    if (!displaySegmentField(dataType, 'shape-type')) {
      return null;
    }
    if (displaySegmentType(dataType, 'polygon')) {
      return (
        <div styleName="shape-type">
          <RadioButtonGroup
            name="shapeType"
            defaultSelected={activeType}
            valueSelected={activeType}
            onChange={onShapeTypeChange}
            {...formStyles().radioGroup}
          >
            <RadioButton
              disabled={readOnly}
              value="Point"
              label={<div styleName="label">Point</div>}
              {...formStyles(readOnly).radio}
            />
            <RadioButton
              disabled={readOnly}
              value="LineString"
              label={<div styleName="label">Segment</div>}
              {...formStyles(readOnly).radio}
            />
            <RadioButton
              disabled={readOnly}
              value="Polygon"
              label={<div styleName="label">Polygon</div>}
              {...formStyles(readOnly).radio}
            />
          </RadioButtonGroup>
          <ShapeTypeTooltip />
        </div>
      );
    }
    return (
      <div styleName="shape-type">
        <RadioButtonGroup
          name="shapeType"
          defaultSelected={activeType}
          valueSelected={activeType}
          onChange={onShapeTypeChange}
          {...formStyles().radioGroup}
        >
          <RadioButton
            disabled={readOnly}
            value="Point"
            label={<div styleName="label">Point</div>}
            {...formStyles(readOnly).radio}
          />
          <RadioButton
            disabled={readOnly}
            value="LineString"
            label={<div styleName="label">Segment</div>}
            {...formStyles(readOnly).radio}
          />
        </RadioButtonGroup>
        <ShapeTypeTooltip />
      </div>
    );
  }, [activeType, dataType, onShapeTypeChange, readOnly]);

  const renderAddress = useCallback(() => {
    if (activeType === 'Polygon') {
      return null;
    }
    if (!displaySegmentField(dataType, 'addresses')) {
      return null;
    }
    const fromName = activeType === 'LineString' ? 'From address*' : 'Address*';
    const toName = 'To address*';
    let fromError = segment.display_from_error;
    let toError = segment.display_to_error;
    if (checkAddressErrors &&
       (isEmpty(segment.display_from) ||
       isEmpty(segment.display_to))) {
      fromError = 'Enter an address';
      toError = 'Enter an address';
    }
    return (
      <div styleName="address">
        <div styleName="from-to">
          <div>
            <InputAutocomplete
              initName="placeFrom"
              inputProps={{
                disabled: readOnly,
                errorText: fromError,
                floatingLabelText: fromName,
                name: fromName,
                onChange: onFromChange,
                value: segment.display_from || '',
                ...detailEdit.segmentEdit.textField
              }}
              onGeocodeCompleted={onFromGeocode}
              onGeocodeError={onFromGeocodeError}
            />
          </div>
          {activeType === 'LineString' &&
            <div>
              <InputAutocomplete
                initName="placeTo"
                inputProps={{
                  disabled: readOnly,
                  errorText: toError,
                  floatingLabelText: toName,
                  onChange: onToChange,
                  value: segment.display_to || '',
                  ...detailEdit.segmentEdit.textField
                }}
                onGeocodeCompleted={onToGeocode}
                onGeocodeError={onToGeocodeError}
              />
            </div>
          }
        </div>
        {activeType === 'LineString' && !readOnly &&
          <div styleName="swap">
            <IconButton onClick={swapAddresses} tooltip="Swap from/to">
              <Icon>swap_vert</Icon>
            </IconButton>
          </div>
        }
      </div>
    );
  }, [
    activeType,
    checkAddressErrors,
    dataType,
    onFromChange,
    onFromGeocode,
    onFromGeocodeError,
    onToChange,
    onToGeocode,
    onToGeocodeError,
    readOnly,
    segment,
    swapAddresses
  ]);

  const renderSnapAndAlley = useCallback(() => {
    if (activeType === 'Polygon') {
      return null;
    }
    if (!displaySegmentField(dataType, 'snap_n_alley')) {
      return null;
    }
    const segmentFieldConfig = getSegmentFieldConfig(dataType, 'snap_n_alley');
    return (
      <div styleName="snap-n-alley">
        <Toggle
          label={'label' in segmentFieldConfig ? segmentFieldConfig.label : 'Snap to streets'}
          labelPosition="right"
          name="override_street_centering"
          disabled={readOnly}
          toggled={!segment.override_street_centering}
          onToggle={onCheck}
          {...formStyles(readOnly).toggle}
        />
        <SnapTooltip />
        {segment.override_street_centering && !('hide_alley_toggle' in segmentFieldConfig) &&
          <span style={{ paddingLeft: '1.5rem' }}>
            <Checkbox
              checked={(segment.override_street_centering || false) && (segment.is_alley || false)}
              disabled={!segment.override_street_centering || readOnly}
              label="This is an alley"
              name="is_alley"
              onChange={onCheck}
              size="small"
              style={{ padding: '0 10px 0 10px' }}
            />
          </span>
        }
      </div>
    );
  }, [
    activeType,
    dataType,
    onCheck,
    readOnly,
    segment
  ]);

  const renderImpact = useCallback(() => {
    if (!displaySegmentField(dataType, 'impact')) {
      return null;
    }
    return (
      <div styleName="impact">
        <DataTypesSelect
          dataName="segment_direction"
          disabled={readOnly}
          floatingLabelText="Direction of impact"
          name="direction"
          onChange={onDirectionChange}
          styleName="direction"
          value={segment.direction}
        />
        <DataTypesSelect
          dataName="segment_impact_type"
          disabled={readOnly}
          floatingLabelText="Type of impact"
          name="impact_type"
          onChange={onImpactChange}
          styleName="impact-type"
          value={segment.impact_type}
        />
      </div>
    );
  }, [
    dataType, onDirectionChange, onImpactChange, readOnly, segment
  ]);

  const renderTitle = useCallback(() => {
    if (!displaySegmentField(dataType, 'title')) {
      return null;
    }
    const segmentFieldConfig = getSegmentFieldConfig(dataType, 'title');
    return (
      <div styleName="title">
        <TextField
          {...detailEdit.segmentEdit.textField}
          disabled={readOnly}
          name="Title"
          floatingLabelText={'label' in segmentFieldConfig ? segmentFieldConfig.label : 'Title'}
          onChange={onTitleChange}
          value={segment.title}
        />
        <div styleName="text-field-footer">
          <div styleName="hint">
            Replaces the header with a custom title instead of On Street value.
          </div>
          <div styleName="counter">
            {segment.title ? segment.title.length : 0}/{MAX_TITLE_LEN}
          </div>
        </div>
      </div>
    );
  }, [
    dataType, onTitleChange, readOnly, segment
  ]);

  const renderDescription = useCallback(() => {
    if (!displaySegmentField(dataType, 'description')) {
      return null;
    }
    const segmentFieldConfig = getSegmentFieldConfig(dataType, 'description');
    return (
      <div styleName="description">
        <TextField
          {...detailEdit.segmentEdit.textField}
          disabled={readOnly}
          multiLine
          name="Description"
          floatingLabelText={'label' in segmentFieldConfig ? segmentFieldConfig.label : 'Description'}
          onChange={onDescriptionChange}
          value={segment.description}
        />
        <div styleName="text-field-footer">
          <div styleName="hint">
            Describe any unique attributes or notes associated to selected location.
          </div>
          <div styleName="counter">
            {segment.description ? segment.description.length : 0}/{MAX_DESCRIPTION_LEN}
          </div>
        </div>
      </div>
    );
  }, [
    dataType, onDescriptionChange, readOnly, segment
  ]);

  const renderCNN = useCallback(() => {
    if (!displaySegmentField(dataType, 'cnn')) {
      return null;
    }
    return (
      <div styleName="description">
        <TextField
          {...detailEdit.segmentEdit.textField}
          disabled={readOnly}
          multiLine
          name="CNN"
          floatingLabelText="CNN"
          value={segment.cnn}
          readOnly
        />
      </div>
    );
  }, [
    dataType, readOnly, segment.cnn
  ]);

  const displaySchedule = useCallback(() => displaySegmentField(dataType, 'schedule'), [dataType]);

  const renderSchedule = useCallback(() => {
    if (!displaySchedule()) {
      return null;
    }
    if (isEmpty(segment.schedules)) {
      return null;
    }
    return (
      <div styleName="schedule">
        {renderScheduleString(segment.schedules)}
        {!readOnly && !isEmpty(segment.schedules) && (
          <div styleName="schedule-edit-icon">
            <IconButton onClick={editSchedule} size="small" tooltip="Edit Schedule">
              <Icon color={colors.opacity.black0_6}>edit</Icon>
            </IconButton>
          </div>
        )}
        {!readOnly && !isEmpty(segment.schedules) && (
          <div styleName="schedule-remove-icon">
            <IconButton onClick={onClearSchedule} size="small" tooltip="Remove Schedule">
              <Icon color={colors.opacity.black0_6}>close</Icon>
            </IconButton>
          </div>
        )}
      </div>
    );
  }, [displaySchedule, editSchedule, onClearSchedule, readOnly, segment]);

  const polygonInfoStyle = useMemo(() => `polygon-info ${checkAddressErrors ? 'polygon-error' : ''}`, [checkAddressErrors]);

  return (
    <div styleName="segment-edit-list-item">
      <div onClick={onClick} role="presentation" styleName="action-header">
        <div styleName="label">{getSegmentTitle(segment)}</div>
        <div styleName="icon">
          <Icon>unfold_less</Icon>
        </div>
      </div>
      {renderShapeType()}
      {renderAddress()}
      {renderSnapAndAlley()}
      {activeType === 'Polygon' && !segment.shape &&
        <div styleName={polygonInfoStyle}><span>Draw a polygon on the map</span></div>
      }
      {activeType === 'Polygon' && segment.shape &&
        <div styleName="polygon-info">
          <span>Polygon</span>
          {!readOnly && <Button onClick={clearPolygon}>CLEAR</Button>}
        </div>
      }
      {renderImpact()}
      {renderTitle()}
      {renderDescription()}
      {renderCNN()}
      {displaySchedule() && showMissingDatesWarning(data.start_date, data.end_date) &&
        <div styleName="segment-edit-warning">
          {renderWarning(`${capitalize(getEntityTypeLabel(dataType))} schedule is missing.`)}
        </div>
      }
      {displaySchedule() && showScheduleExceedsWarning(data.start_date, data.end_date, segment) &&
        <div styleName="segment-edit-warning">
          {renderWarning(`Schedule exceeds the ${getEntityTypeLabel(dataType)} schedule.`)}
        </div>
      }
      {renderSchedule()}
      {displaySchedule() && !readOnly && isEmpty(segment.schedules) &&
        <div styleName="schedule-button">
          <Button color="primary" onClick={editSchedule}>
            SET SCHEDULE
          </Button>
        </div>
      }
      {!readOnly && <div styleName="separator" />}
      {!readOnly &&
        <div styleName="action">
          <div styleName="empty" />
          <IconButton onClick={onClone} tooltip="Duplicate" style={{ opacity: '0.6' }}>
            <Icon>content_copy</Icon>
          </IconButton>
          <IconButton onClick={onDeleteSegment} tooltip="Delete" style={{ opacity: '0.6' }}>
            <Icon>delete</Icon>
          </IconButton>
        </div>
      }
    </div>
  );
};

SegmentEditListItem.propTypes = {
  // If true, check if addresses are empty and show the error.
  checkAddressErrors: PropTypes.bool,
  data: PropTypes.object,
  dataType: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool
};

export default memo(SegmentEditListItem);
