import React from 'react';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import { dotmapsBlue } from '@constants/colors';
import { Icon } from '@mui';
import UserAvatar from '@shared/user-avatar';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import UserTeams from '@utils/data-table/column-renderers/user-teams';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const getUserActive = is_active => is_active ? 'Active' : 'Inactive';

export const getUserAvatar = (user, size = 'medium', onClick = null) => (
  <UserAvatar onClick={onClick} size={size} user={user} />
);

export const getNoUserIconAvatar = (size = 'medium', color = dotmapsBlue) => {
  const fontSize = size === 'medium' ? 32 : 40;
  return (
    <Icon color={color} style={{ display: 'flex', fontSize, justifyContent: 'center' }}>
      account_circle
    </Icon>
  );
};

export const renderUsername = (user, id) => (
  <TruncatedCell tooltip={user.name} targetId={id}>
    {renderCustomIconAndLabel(user.name, getUserAvatar(user), id)}
  </TruncatedCell>
);

export const optimizeUserData = (user, agencies, user_roles) => ({
  ...user,
  is_active: getUserActive(user.is_active),
  name: renderUsername(user, uuid()),
  agency: <TruncatedCell>{R.pathOr(enDash, [user.agency, 'name'], agencies)}</TruncatedCell>,
  last_modified: user.last_modified ? renderTooltipDate(user.last_modified) : enDash,
  last_login: user.last_login ? renderTooltipDate(user.last_login) : enDash,
  roles: user.roles.length ? <UserTeams roles={user.roles} userRoles={user_roles} /> : enDash
});
