import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { convertToJSON, uploadBatch, validateBatch } from '@actions/batch-actions';
import { closeDashboardDialog } from '@actions/dashboard-actions';

import BatchUploadDownloadTemplate from '@components/portal/table/batch-upload-download-template';
import BatchUploadDrawer from '@components/portal/table/batch-upload-drawer';
import BatchUploadDropzone from '@components/portal/table/batch-upload-dropzone';
import BatchUploadEntitiesSelectFields from '@components/portal/table/batch-upload-entities-select-fields';
import BatchUploadErrors from '@components/portal/table/batch-upload-errors';
import BatchUploadFileSummary from '@components/portal/table/batch-upload-file-summary';
import BatchUploadSelectType from '@components/portal/table/batch-upload-select-type';
import BatchUploadStepper from '@components/portal/table/batch-upload-stepper';

import * as dialog from '@constants/dialogs';
import {
  UPLOAD_RUNNING,
  UPLOAD_FINISHED
} from '@constants/file';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Select } from '@mui';

import Dialog from '@shared/dialogs/dialog';
import DialogActions from '@shared/dialogs/dialog-actions';

import { saveBatch } from '@utils/batch-utils';
import { sortByField } from '@utils/data-types-utils';
import { canAccessEntity } from '@utils/permission-utils';

import './batch-upload-dialog.scss';

const { MenuItem } = Select;

// Restrict file uploads to 8 mb:
const MAX_UPLOAD_SIZE = 8388608;

const isCSV = file => file.name.split('.').pop()
  .toLowerCase() === 'csv';

const getSelectedFields = fields => Object.keys(fields).filter(key => fields[key]);

const BatchUploadEntitiesDialog = () => {
  const dispatch = useDispatch();
  const { activeDialogs } = useSelector(state => state.dashboard);
  const { map_type: entityTypes } = useSelector(state => state.dataTypes);
  const [activeStep, setActiveStep] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [entity, setEntity] = useState(null);
  const [errors, setErrors] = useState(null);
  const [fields, setFields] = useState({});
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState(null);
  const [summary, setSummary] = useState({});

  const isDialogActive = useMemo(() => activeDialogs[dialog.BATCH_ENTITY], [activeDialogs]);

  const selectedFields = useMemo(() => getSelectedFields(fields), [fields]);

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.BATCH_ENTITY));
      // When closing the dialog, reset the state:
      setActiveStep(0);
      setDrawerOpen(false);
      setEntity(null);
      setErrors(null);
      setFile(null);
      setStatus(null);
      setSummary({});
    },
    [dispatch, setActiveStep, setDrawerOpen, setEntity, setErrors, setFile, setStatus, setSummary]
  );

  useEffect(() => {
    // When upload finishes, close the dialog.
    if (status === UPLOAD_FINISHED) {
      onClose();
    }
  }, [onClose, status]);

  const onProgress = useCallback(event => {
    setStatus(event.loaded === event.total ? UPLOAD_FINISHED : UPLOAD_RUNNING);
  }, [setStatus]);

  const onError = useCallback(error => setErrors([error]), [setErrors]);

  const closeDrawer = useCallback(() => setDrawerOpen(false), [setDrawerOpen]);

  const openDrawer = useCallback(() => setDrawerOpen(true), [setDrawerOpen]);

  const onValidationResults = useCallback(results => {
    const { unique_id, total_new, total_updated, total, errors: validationErrors } = results;
    setSummary({
      unique_id, total_new, total_updated, total
    });
    const errorList = [];
    if (file.size > MAX_UPLOAD_SIZE) {
      errorList.push('File must be under 8MB.');
    }
    if (file.size === 0) {
      errorList.push('No data in the CSV.');
    }
    if (errorList.length > 0 || validationErrors?.length > 0) {
      setErrors([...(errors || []), ...errorList, ...(validationErrors || [])]);
    }
  }, [errors, file, setErrors, setSummary]);

  const validateFile = useCallback(
    () => {
      if (file) {
        validateBatch(file, entity, onValidationResults);
      }
    },
    [entity, file, onValidationResults]
  );

  useEffect(() => {
    // Run validation when the entity type and the file to upload are selected
    // and there are no frontend validation errors.
    if (entity && file && !errors) {
      if (!isCSV(file)) {
        setErrors(['File type is not CSV.']);
      } else {
        validateFile();
      }
    }
  }, [entity, errors, file, validateFile]);

  const onUpload = useCallback(
    () => {
      if (file) {
        const upload = {
          active: true,
          file,
          entityType: entity,
          status: 0
        };
        dispatch(uploadBatch(upload, onProgress, onError));
      }
    },
    [dispatch, entity, file, onProgress, onError]
  );

  const onConvertFinished = useCallback(data => {
    const { fileId, rows } = saveBatch(entity, file, data);  // Save file into session storage.
    dispatch(push(`/batch-upload-edit/${fileId}/${rows?.[0]?.id || ''}`));
  }, [entity, file]);

  const onNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);

    // The last step should close the dialog and redirect to the edit batch page.
    if (activeStep === 1) {
      onClose();
      convertToJSON(file, entity, selectedFields, onError, onConvertFinished);
    }
  }, [activeStep, entity, file, onClose, selectedFields, setActiveStep]);

  const filteredEntities = useMemo(() => sortByField('order')(R.values(entityTypes)), [entityTypes]);

  const onChange = useCallback(event => {
    setEntity(event.target.value);
  }, [setEntity]);

  const onFileSelect = useCallback(files => {
    if (files?.length > 0) {
      const newFile = files[0];
      setFile(newFile);
    } else if (file) {
      setFile(null);
    }
  }, [file, setFile]);

  const onFileReset = useCallback(() => {
    setErrors(null);
    setFile(null);
    setSummary({});
  }, [setErrors, setFile]);

  const renderer = useCallback(values => {
    if (values?.length === 0 || !entity) {
      return 'Select';
    }
    return filteredEntities.find(item => item.name === entity).label;
  }, [entity, filteredEntities]);

  const menuItems = useMemo(() => filteredEntities.map(item => (
    <MenuItem key={item.name} value={item.name}>
      {item.label}
    </MenuItem>
  )), [filteredEntities]);

  // Returns true if the user has at least selected a column to upload during the column selection step.
  const isAnyColumnSelected = useMemo(() => activeStep !== 1 || selectedFields.length > 0, [activeStep, selectedFields]);

  const actions = useMemo(() => (
    <DialogActions
      disabledSubmit={!file || errors || !entity || !isAnyColumnSelected}
      onCancel={onClose}
      onSubmit={onNext}
      submitLabel="NEXT"
      submitProps={{ endIcon: <ChevronRightIcon /> }}
    />
  ), [entity, errors, file, isAnyColumnSelected, onClose, onNext]);

  const maxWidth = useMemo(() => drawerOpen ? '70rem' : '47.5rem', [drawerOpen]);

  if (!isDialogActive || !canAccessEntity(null, 'batchfile', 'add')) {
    return null;
  }

  return (
    <Dialog actions={actions} contentStyle={{ padding: 0 }} dividers maxWidth={maxWidth} open title="Upload Batch: Entities">
      <div styleName="dialog-container">
        <BatchUploadStepper activeStep={activeStep} />
        {activeStep === 0 &&
          <Fragment>
            <div styleName="dialog-content">
              <BatchUploadSelectType
                menuItems={menuItems}
                onChange={onChange}
                renderer={renderer}
                title="Upload type"
                value={entity}
              />
              {entity && <BatchUploadDownloadTemplate type={entity} />}
              <BatchUploadDropzone
                accept="text/csv"
                caption="CSV, 8MB max"
                file={file}
                onFileChange={onFileSelect}
                onFileReset={onFileReset}
                openDrawer={openDrawer}
                rowCount={summary.total}
                status={status}
                value={entity}
              />
              {errors && <BatchUploadErrors errors={errors} />}
              {entity && <BatchUploadFileSummary summary={summary} />}
            </div>
            {entity && drawerOpen && <BatchUploadDrawer entity={entity} onClose={closeDrawer} />}
          </Fragment>
        }
        {activeStep === 1 && <BatchUploadEntitiesSelectFields
          entity={entity}
          fields={fields}
          file={file}
          isAnyColumnSelected={isAnyColumnSelected}
          setFields={setFields}
        />}
      </div>
    </Dialog>
  );
};

export default memo(BatchUploadEntitiesDialog);
