/* eslint-disable react/jsx-no-bind */
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { COOKIE_PATH, DISCLAIMER_KEY } from '@constants/auth';

import Button from '@material-ui/core/Button';

import Dialog from '@shared/dialogs/dialog';

import { getCookies } from '@utils/shared-utils';

const Disclaimer = () => {
  const [visible, setVisible] = useState(getCookies().get(DISCLAIMER_KEY) !== true);

  const disclaimer = useSelector(state => state.config?.disclaimer);

  const onDismiss = () => {
    getCookies().set(DISCLAIMER_KEY, true, COOKIE_PATH);
    setVisible(false);
  };

  if (!disclaimer || !visible) {
    return null;
  }

  if (getCookies().get(DISCLAIMER_KEY) === true) {
    return null;
  }

  return (
    <Dialog
      actions={
        <Button color="primary" onClick={onDismiss} variant="contained">OK</Button>
      }
      title="Disclaimer"
    >
      {disclaimer}
    </Dialog>
  );
};

export default memo(Disclaimer);
