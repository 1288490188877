/* eslint-disable guard-for-in */
import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getSchema } from '@actions/batch-actions';

import BatchUploadErrors from '@components/portal/table/batch-upload-errors';
import Row from '@components/portal/table/batch-upload-entities-select-fields-row';

import EntityTypeIcon from '@icons/entity-type-icon';

import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { renderFileIcon } from '@utils/file-utils';

import './batch-upload-entities-select-fields.scss';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
});

const BatchUploadEntitiesSelectFields = ({ entity, fields, file, isAnyColumnSelected, setFields }) => {
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  // Load schema (it's the field list for the user to select).
  useEffect(() => {
    if (entity && file) {
      getSchema(file, entity, setError, setRows);
    }
  }, [entity, file, setError, setRows]);

  // Initialize fields when rows are loaded:
  useEffect(() => {
    if (rows.length > 0) {
      setFields(
        rows.filter(row => !row.disabled).map(({ name }) => ({ [name]: true }))
          .reduce((acc, obj) => {
            return { ...acc, ...obj };
          }, {})
      );
    }
  }, [rows, setFields]);

  const onToggleAll = useCallback(() => {
    setFields(prevFields => {
      return Object.fromEntries(Object.keys(prevFields).map(key => [key, !isAnyColumnSelected]));
    });
  }, [isAnyColumnSelected, setFields]);

  const onToggle = useCallback(event => {
    setFields({ ...fields, [event.target.name]: event.target.checked });
  }, [fields, setFields]);

  return (
    <div styleName="select-fields-container">
      <div styleName="select-fields-header">
        <div styleName="select-fields-title">
          Select the columns you want to upload:
        </div>
        <div styleName="select-fields-subtitle">
          Unselected columns will be ignored and keep their existing values.
          Please select all required fields if you're creating entities. * Required field.
        </div>
      </div>
      {error && <BatchUploadErrors errors={[error]} />}
      {!error && (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '2rem' }}>
                  <Switch checked={isAnyColumnSelected} color="primary" onChange={onToggleAll} />
                </TableCell>
                <TableCell>
                  <div styleName="select-fields-file-item-container">
                    <div styleName="select-fields-file-item-icon">
                      {renderFileIcon(file.type)}
                    </div>
                    <div styleName="select-fields-file-item-name">
                      {file.name}
                    </div>
                  </div>
                </TableCell>
                <TableCell style={{ width: '1rem' }}>
                  {/* empty arrow column */}
                </TableCell>
                <TableCell>
                  <div styleName="select-fields-file-item-container">
                    <div styleName="select-fields-file-item-icon">
                      <EntityTypeIcon type={entity} size="1.5rem" color="#616161" />
                    </div>
                    <div styleName="select-fields-file-item-name">
                      {entity}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <Row
                  children={row.children}
                  disabled={row.disabled}
                  fields={fields}
                  key={row.name}
                  label={row.label}
                  name={row.name}
                  onToggle={onToggle}
                  required={row.required}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

BatchUploadEntitiesSelectFields.propTypes = {
  entity: PropTypes.string,
  fields: PropTypes.object,
  file: PropTypes.object,
  isAnyColumnSelected: PropTypes.bool,
  setFields: PropTypes.func
};

export default memo(BatchUploadEntitiesSelectFields);
