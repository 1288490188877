
// Name of the key used to store the token.
export const AUTH_TOKEN_NAME = 'auth_token';

// Timestamp of the last user activity.
export const AUTH_LAST_ACTIVITY_TIME = 'auth_last_activity_time';

// The login route.
export const AUTH_LOGIN_ROUTE = '/login/';

// The logged in username:
export const AUTH_USERNAME = 'auth_username';

// Disclaimer:
export const DISCLAIMER_KEY = 'disclaimer_show';

export const COOKIE_PATH = { path: '/' };
