/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setLayerVisible,
  fetchLayerConfig
} from '@actions/layers-actions';
import { parentOrderedLayersSelector } from '@selectors/layers-selector';
import FilterCheckbox from '@shared/filter-checkbox';
import { filterLayerIcon } from '@utils/icon-utils';
import { BaseTrayFilter, baseMapDispatchToProps, baseMapStateToProps } from './base-filter';
import './filter.scss';

class LayersFilter extends BaseTrayFilter {
  UNSAFE_componentWillMount() {
    if (this.props.layers.length === 0) {
      this.props.fetchLayerConfig();
    }
  }

  onToggleClick = () => {
    this.props.toggleMapFilterToggle('layer');
  };

  getChildren = layer => this.props.layers.filter(item => item.parent_id === layer.id);

  getChecked = layer => {
    if (layer.children) {
      const children = this.getChildren(layer);
      const allVisible = children.every(item => item.visible === true);
      return allVisible;
    }
    return layer.visible;
  };

  toggleLayer = layer => {
    if (layer.children) {
      const isAllChecked = this.getChecked(layer);
      const children = this.getChildren(layer);
      children.forEach(item => {
        this.props.setLayerVisible(item.name, !isAllChecked);
      });
      return;
    }
    this.props.setLayerVisible(layer.name, (!layer || !layer.visible));
  };

  getLayer = layer => (
    <div styleName="list-item" key={layer.name}>
      {layer.parent_id && <div styleName="children-indent" />}
      <div styleName="checkbox">
        <FilterCheckbox
          checked={this.getChecked(layer)}
          disabled={!this.props.filterEnabled}
          onChange={() => this.toggleLayer(layer)}
        />
      </div>
      {!layer.children && (
        <div styleName="icon">
          {filterLayerIcon(layer.icon)}
        </div>
      )}
      <div styleName="label">
        {layer.label}
      </div>
    </div>
  );

  getContent = () => (
    <div styleName="content-list-container">
      {this.props.layers.map(layer => this.getLayer(layer))}
    </div>
  );
}

LayersFilter.propTypes = {
  fetchLayerConfig: PropTypes.func,
  layers: PropTypes.array,
  setLayerVisible: PropTypes.func
};

const mapStateToProps = state => {
  const { layer: filterEnabled } = state.map.filterToggles;
  const layers = parentOrderedLayersSelector(state);
  return { ...baseMapStateToProps(state), filterEnabled, layers };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  fetchLayerConfig,
  setLayerVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(LayersFilter);
